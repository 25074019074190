import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Packages(): JSX.Element {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="pricing-area page-section scroll-to-page" id="packages">
      <div className="custom-container">
        <div className="pricing-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-dollar-sign"></i> packages
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              My <span>Packages</span>
            </h1>
          </div>

          <div className="pricing-table-items">
            <div className="row">
              <div className="col-md-6 scroll-animation" data-aos="fade-up">
                <div className="pricing-table">
                  <div className="pricing-table-header">
                    <div className="top d-flex justify-content-between align-items-start">
                      <h4>Presale</h4>
                      <p className="text-right">
                        Need to prepare the
                        <br /> architectural proposal <br />
                        for a potential customer?
                      </p>
                    </div>
                    {/* <h1>
                      $44 <span>/ hour</span>
                    </h1> */}
                  </div>
                  <ul className="feature-lists">
                    <li>
                      <b>Time Frame:</b>
                    </li>
                    <li>~ 1 - 2 weeks</li>
                    <li>
                      <b>Activities:</b>
                    </li>
                    <li>- Initial consultation with the client</li>
                    <li>
                      - Preliminary requirements gathering (Questionnaire)
                    </li>
                    <li>
                      <b>Deliverables:</b>
                    </li>
                    <li>- Solution Proposal</li>
                    <li>- Initial cost and time estimate</li>
                  </ul>
                  {/* <a href="" className="theme-btn">
                    pick this package
                  </a> */}
                </div>
              </div>
              <div className="col-md-6 scroll-animation" data-aos="fade-left">
                <div className="pricing-table">
                  <div className="pricing-table-header">
                    <div className="top d-flex justify-content-between align-items-start">
                      <h4>Discovery</h4>
                      <p className="text-right">
                        Have the customer engaded and interested in more
                        detailed vision?
                      </p>
                    </div>
                    {/* <h1>
                      $54 <span>/ hour</span>
                    </h1> */}
                  </div>
                  <ul className="feature-lists">
                    <li>
                      <b>Time Frame:</b>
                    </li>
                    <li>~ 3 - 4 weeks</li>
                    <li>
                      <b>Activities:</b>
                    </li>
                    <li>
                      - Detailed requirement gathering through interviews and
                      workshops
                    </li>
                    <li>- Analysis of existing systems (if applicable)</li>
                    <li>- Identification of technology and tools to be used</li>
                    <li>- Definition of project scope and boundaries</li>
                    <li>
                      <b>Deliverables:</b>
                    </li>
                    <li>- Architecture Vision document</li>
                    <li>- Detailed cost and time estimate</li>
                  </ul>
                  {/* <a href="" className="theme-btn">
                    pick this package
                  </a> */}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 scroll-animation" data-aos="fade-up">
                <div className="pricing-table">
                  <div className="pricing-table-header">
                    <div className="top d-flex justify-content-between align-items-start">
                      <h4>Assesment</h4>
                      <p className="text-right">
                        Have a project and need to ensure it's correcly built?
                      </p>
                    </div>
                    {/* <h1>
                      $60 <span>/ hour</span>
                    </h1> */}
                  </div>
                  <ul className="feature-lists">
                    <li>
                      <b>Time Frame:</b>
                    </li>
                    <li>~ 3 - 4 weeks</li>
                    <li>
                      <b>Activities:</b>
                    </li>
                    <li>
                      - Evaluation of current systems, software, and hardware
                      (if upgrading an existing solution)
                    </li>
                    <li>- Technology compatibility assessment</li>
                    <li>
                      - Risk analysis and identification of potential challenges
                    </li>
                    <li>
                      - Compliance check with legal and regulatory requirements
                    </li>
                    <li>
                      <b>Deliverables:</b>
                    </li>
                    <li>- Assessment report with recommendations</li>
                  </ul>
                  {/* <a href="" className="theme-btn">
                    pick this package
                  </a> */}
                </div>
              </div>
              <div className="col-md-6 scroll-animation" data-aos="fade-left">
                <div className="pricing-table">
                  <div className="pricing-table-header">
                    <div className="top d-flex justify-content-between align-items-start">
                      <h4>Consulting</h4>
                      <p className="text-right">
                        Need help with a particular case or issue?
                      </p>
                    </div>
                    {/* <h1>
                      $66 <span>/ hour</span>
                    </h1> */}
                  </div>
                  <ul className="feature-lists">
                    <li>
                      <b>Time Frame:</b>
                    </li>
                    <li>
                      Customizable (e.g., single session, a block of hours,
                      etc.)
                    </li>
                    <li>
                      <b>Activities:</b>
                    </li>
                    <li>
                      - Pre-meeting communication to understand the issues or
                      challenges
                    </li>
                    <li>- Scheduled meetings with the client</li>
                    <li>- Analysis of specific architectural issues</li>
                    <li>
                      <b>Deliverables:</b>
                    </li>
                    <li>- Summary of the issues discussed</li>
                    <li>- Detailed analysis and recommendations report</li>
                    <li>
                      - Follow-up email or documentation with key points and
                      action items
                    </li>
                  </ul>
                  {/* <a href="" className="theme-btn">
                    pick this package
                  </a> */}
                </div>
              </div>
            </div>
            <p className="info scroll-animation" data-aos="fade-up">
              Can't find any package that matches your plan, or interested in
              pricing details?
              <br />
              I'll set up a tailor-made package only for you!{' '}
              <a href="mailto:igor@architech.one?subject=I'd like a tailor-made package!">
                Contact Me
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
