import React from 'react';
import Rsidemenu from './Global-content/Rsidemenu';
import Scrollnav from './Global-content/Scrollnav';
import Lsb from './Global-content/Lsb';
import MainWrapper from './MainWrapper';

export default function Global(): JSX.Element {
  return (
    <div>
      {/* Left Side Bar and it will get executed on large devices*/}
      <Lsb />

      {/*when user interacts with the hamburger menu it display the inner-components*/}
      <Rsidemenu />

      {/*fixed on large screens easy to navigate through section og main components*/}
      <Scrollnav />

      {/* heroSection, about, testimoni  */}
      <MainWrapper />
    </div>
  );
}
