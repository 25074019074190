import './assets/css/bootstrap.min.css';
import './css/App.css';
import './css/responsive.css';
import React from 'react';
import Global from './components/Global';

function App(): JSX.Element {
  return <Global />;
}

export default App;

// --primary_color: #808a33;
