import React, { useState } from 'react';

export default function Lsb(): JSX.Element {
  const [currentYear] = useState<number>(new Date().getFullYear());

  return (
    <div className="left-sidebar">
      <div className="sidebar-header d-flex align-items-center justify-content-between">
        <img src="./assets/images/logo.png" alt="Logo" />
        <span className="designation">Solutions Architect</span>
      </div>
      <img className="me" src="./assets/images/me.jpg" alt="Me" />
      <h2 className="email">Igor Golovchuk</h2>
      <h2 className="address">Empowering Digital Dreams</h2>
      <p className="copyright">
        &copy; {currentYear} architech.one | All Rights Reserved
      </p>
      <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
        <li>
          <a href="https://www.linkedin.com/in/igor-golovchuk/" target="_blank">
            <i className="lab la-linkedin"></i>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@rchitech" target="_blank">
            <i className="lab la-youtube"></i>
          </a>
        </li>
        <li>
          <a href="https://github.com/igolovchuk/" target="_blank">
            <i className="lab la-github"></i>
          </a>
        </li>
      </ul>
      <a
        href="mailto:igor@architech.one?subject=I'd like to architech one!"
        className="theme-btn"
      >
        <i className="las la-envelope"></i> Contact Me
      </a>
    </div>
  );
}
