import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Resume(): JSX.Element {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
      <div className="custom-container">
        <div className="resume-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-briefcase"></i> Resume
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              Education & <span>Experience</span>
            </h1>
          </div>

          <div className="resume-timeline">
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">2020 - Present</span>
              <h2>Certified: Azure Solutions Architect Expert</h2>
              <p>Microsoft</p>
              <h2>Application Architect</h2>
              <p>N-iX</p>
              <h2>Architecture University</h2>
              <p>N-iX</p>
              <h2>Lead | Principal Software Engineer</h2>
              <p>N-iX</p>
            </div>
            <div className="item scroll-animation" data-aos="fade-left">
              <span className="date">2017 - 2020</span>
              <h2>Solution Architecture School</h2>
              <p>EPAM Systems</p>
              <h2>Senior | Lead Software Engineer</h2>
              <p>EPAM Systems</p>
              <h2>Certified: MCSA, Web Applications</h2>
              <p>Microsoft</p>
              <h2>Senior | Lead Engineer, Academy Tech Expert</h2>
              <p>SoftServe</p>
              <h2>Certified: Developing ASP.NET MVC Web Applications</h2>
              <p>SoftServe</p>
              <h2>Middle | Senior Software Engineer</h2>
              <p>Arrow Digital</p>
            </div>
            <div className="item scroll-animation" data-aos="fade-right">
              <span className="date">2011 - 2016</span>
              <h2>Certified: Programming in C# </h2>
              <p>Microsoft</p>
              <h2>Junior | Middle Software Engineer </h2>
              <p>Eleks</p>
              <h2>Trainee | Junior Software Engineer</h2>
              <p>Skaveetech</p>
              <h2>Master's Degree, Computer-Aided Design (CAD)</h2>
              <p>Lviv Polytechnic National University</p>
              <h2>Bachelor's Degree, Computer Science</h2>
              <p>Lviv Polytechnic National University</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
