import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Skills(): JSX.Element {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="skills-area page-section scroll-to-page" id="skills">
      <div className="custom-container">
        <div className="skills-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-shapes"></i> my skills
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              I've designed solutions <span>based on:</span>
            </h1>
          </div>

          <div className="row skills text-center">
            <div className="col-md-4 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img
                    src="../assets/images/svg/aws.svg"
                    alt="AWS"
                    width={60}
                    height={60}
                  />
                  <h1 className="percent">AWS</h1>
                </div>
                {/* <p className="name">AWS</p> */}
              </div>
            </div>
            <div className="col-md-4 scroll-animation" data-aos="fade-up">
              <div className="skill">
                <div className="skill-inner">
                  <img
                    src="../assets/images/svg/azure.svg"
                    width={60}
                    height={60}
                    alt="Azure"
                  />
                  <h1 className="percent">Azure</h1>
                </div>
                {/* <p className="name">Framer</p> */}
              </div>
            </div>
            <div className="col-md-4 scroll-animation" data-aos="fade-down">
              <div className="skill">
                <div className="skill-inner">
                  <img
                    src="../assets/images/svg/gcp.svg"
                    width={60}
                    height={60}
                    alt="Azure"
                  />
                  <h1 className="percent">GCP</h1>
                </div>
                {/* <p className="name">Webflow</p> */}
              </div>
            </div>
            <div className="col-md-4 scroll-animation" data-aos="fade-left">
              <div className="skill">
                <div className="skill-inner">
                  <img
                    src="../assets/images/svg/dotnet.svg"
                    width={60}
                    height={60}
                    alt="Dotnet"
                  />
                  <h1 className="percent">.NET</h1>
                </div>
                {/* <p className="name">React</p> */}
              </div>
            </div>
            <div className="col-md-4 scroll-animation" data-aos="fade-right">
              <div className="skill">
                <div className="skill-inner">
                  <img
                    src="../assets/images/svg/nodejs.svg"
                    width={60}
                    height={60}
                    alt="NodeJS"
                  />
                  <h1 className="percent">NodeJS</h1>
                </div>
                {/* <p className="name">WordPress</p> */}
              </div>
            </div>
            <div className="col-md-4 scroll-animation" data-aos="fade-up">
              <div className="skill">
                <div className="skill-inner">
                  <img
                    src="../assets/images/svg/python.svg"
                    width={60}
                    height={60}
                    alt="Python"
                  />
                  <h1 className="percent">Python</h1>
                </div>
                {/* <p className="name">Laravel/PHP</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
