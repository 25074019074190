import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Service(): JSX.Element {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section
      className="services-area page-section scroll-to-page"
      id="services"
    >
      <div className="custom-container">
        <div className="services-content content-width">
          <div className="section-header">
            <h4 className="subtitle scroll-animation" data-aos="fade-up">
              <i className="las la-stream"></i> Services
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              My <span>Specializations</span>
            </h1>
          </div>

          <div className="services-items">
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-bezier-curve"></i>
              <h2>Microservices Architecture</h2>
              <p>
                Running containers in Kubernetes on-prem or in the cloud. It is
                often used in large-scale applications that require rapid
                development and deployment cycles
              </p>
              {/* <span className="projects">24 Projects</span> */}
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-code"></i>
              <h2>Serverless Architecture</h2>
              <p>
                Also known as Function as a Service (FaaS), is an architectural
                pattern where the cloud provider automatically manages the
                infrastructure for application deployment. In a serverless
                environment, developers write code that is executed in response
                to events such as HTTP requests, database changes, or message
                queue activations, without worrying about server provisioning,
                maintenance, or scaling
              </p>
              {/* <span className="projects">126 Projects</span> */}
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-bezier-curve"></i>
              <h2>AI and Machine Learning Integration</h2>
              <p>
                AI and ML are being integrated into architectures to make them
                more adaptive and responsive.
              </p>
              {/* <span className="projects">8 Projects</span> */}
            </div>
            <div className="service-item scroll-animation" data-aos="fade-up">
              <i className="las la-bezier-curve"></i>
              <h2>Blockchain</h2>
              <p>
                Integrate Smart Contracts into your system for advanced security
                and transparency
              </p>
              {/* <span className="projects">8 Projects</span> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
