import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Herosec(): JSX.Element {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section className="hero-section page-section scroll-to-page" id="home">
      <div className="custom-container">
        <div className="hero-content content-width">
          <div className="section-header">
            <h4
              className="subtitle scroll-animation"
              data-animation="fade_from_bottom"
              data-aos="fade-up"
            >
              <i className="las la-home"></i> Introduce
            </h4>
            <h1 className="scroll-animation" data-aos="fade-up">
              One <span>Architect</span>, Infinite <span>Solutions</span>!
            </h1>
          </div>
          <p className="scroll-animation" data-aos="fade-up">
            I help companies with digital modernisation of architecture and
            infrastructure in order to optimise costs, increase business
            profitability and stay ahead of competitors.
          </p>
          <a
            href="#services"
            className="go-to-project-btn scroll-to scroll-animation"
            data-aos="fade-up"
          >
            <img src="../assets/images/round-text.png" alt="Rounded Text" />
            <i className="las la-arrow-down"></i>
          </a>
          <div className="facts d-flex">
            <div className="left scroll-animation" data-aos="fade-right">
              <h1>10+</h1>
              <p>Years in Software Development</p>
            </div>
            <div className="right scroll-animation" data-aos="fade-left">
              <h1>35+</h1>
              <p>projects completed</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
